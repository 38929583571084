export const lessons = [
    { label: 'IPA', code: 'subject_ipa' },
    { label: 'IPS', code: 'subject_ips' },
    { label: 'Bahasa Indonesia', code: 'subject_indonesia' },
    { label: 'Bahasa Inggris', code: 'subject_inggris' },
    { label: 'Matematika', code: 'subject_matematika' },
    { label: 'Pendidikan Pancasila', code: 'subject_ppkn' },
    { label: 'Informatika', code: 'subject_informatika' },
    { label: 'PJOK', code: 'subject_pjok' },
    { label: 'Agama Islam', code: 'subject_agama_islam' },
    { label: 'Agama Kristen', code: 'subject_agama_kristen' },
    { label: 'Agama Katolik', code: 'subject_agama_katolik' },
    { label: 'Agama Hindu', code: 'subject_agama_hindu' },
    { label: 'Agama Buddha', code: 'subject_agama_budha' },
    { label: 'Agama Khonghucu', code: 'subject_agama_khonghucu' },
    { label: 'Kepercayaan', code: 'subject_kepercayaan' },
    { label: 'Seni Tari', code: 'subject_seni_tari' },
    { label: 'Seni Musik', code: 'subject_seni_musik' },
    { label: 'Seni Rupa', code: 'subject_seni_rupa' },
    { label: 'Seni Teater', code: 'subject_seni_teater' },
    { label: 'Sosiologi', code: 'subject_sosiologi' },
    { label: 'Antropologi', code: 'subject_antropologi' },
    { label: 'Ekonomi', code: 'subject_ekonomi' },
    { label: 'Geografi', code: 'subject_geografi' },
    { label: 'Sejarah', code: 'subject_sejarah' },
    { label: 'Prakarya', code: 'subject_prakarya' },
    { label: 'IPAS', code: 'subject_ipas' },
    { label: 'Teknik Konstruksi dan Perumahan', code: 'subject_teknik_konstruksi_dan_perumahan' },
    { label: 'Teknik Otomotif', code: 'subject_teknik_otomotif' },
    { label: 'Teknik Elektronika', code: 'subject_teknik_elektronika' },
    { label: 'Teknik Pesawat Udara', code: 'subject_teknik_pesawat_udara' },
    { label: 'Teknik Konstruksi Kapal', code: 'subject_teknik_konstruksi_kapal' },
    { label: 'Teknik Ketenagalistrikan', code: 'subject_teknik_ketenagalistrikan' },
    { label: 'Teknik Geospasial', code: 'subject_teknik_geospasial' },
    { label: 'Teknik Geologi Pertambangan', code: 'subject_teknik_geologi_pertambangan' },
    { label: 'Layanan Kesehatan', code: 'subject_layanan_kesehatan' },
    { label: 'Agriteknologi Pengolahan Hasil Pertanian', code: 'subject_agriteknologi_pengolahan_hasil_pertanian' },
    { label: 'Manajemen Perkantoran dan Layanan Bisnis', code: 'subject_manajemen_perkantoran_dan_layanan_bisnis' },
    { label: 'Usaha Layanan Pariwisata', code: 'subject_usaha_layanan_pariwisata' },
    { label: 'Desain Komunikasi Visual', code: 'subject_desain_komunikasi_visual' },
    { label: 'Teknik Furniture', code: 'subject_teknik_furniture' },
    { label: 'Kuliner', code: 'subject_kuliner' },
    { label: 'Biologi', code: 'subject_biologi' },
    { label: 'Fisika', code: 'subject_fisika' },
    { label: 'Kimia', code: 'subject_kimia' },
    { label: 'Seni Budaya', code: 'subject_seni_budaya' },
    { label: 'Dasar-dasar Akuntansi dan Keuangan Lembaga', code: 'subject_dasar_dasar_akuntansi_dan_keuangan_lembaga' },
    { label: 'Dasar-dasar Animasi', code: 'subject_dasar_dasar_animasi' },
    { label: 'Dasar-dasar Broadcasting dan Perfilman', code: 'subject_dasar_dasar_broadcasting_dan_perfilman' },
    { label: 'Dasar-dasar Busana', code: 'subject_dasar_dasar_busana' },
    { label: 'Dasar-dasar Teknologi Farmasi', code: 'subject_dasar_dasar_teknologi_farmasi' },
    { label: 'Projek IPAS', code: 'subject_projek_ipas' },
    { label: 'Dasar-dasar Teknik Jaringan Komputer dan Telekomunikasi', code: 'subject_dasar_dasar_teknik_jaringan_komputer_dan_telekomunikasi' },
    { label: 'Dasar-dasar Nautika Kapal Niaga', code: 'subject_dasar_dasar_nautika_kapal_niaga' },
    { label: 'Dasar-dasar Nautika Kapal Penangkapan Ikan', code: 'subject_dasar_dasar_nautika_kapal_penangkapan_ikan' },
    { label: 'Dasar-dasar Kecantikan dan Spa', code: 'subject_dasar_dasar_kecantikan_dan_spa' },
    { label: 'Dasar-dasar Kehutanan', code: 'subject_dasar_dasar_kehutanan' },
    { label: 'Dasar-dasar Pekerjaan Sosial', code: 'subject_dasar_dasar_pekerjaan_sosial' },
    { label: 'Dasar-dasar Teknik Pengelasan dan Fabrikasi Logam', code: 'subject_dasar_dasar_teknik_pengelasan_dan_fabrikasi_logam' },
    { label: 'Dasar-dasar Perhotelan', code: 'subject_dasar_dasar_perhotelan' },
    { label: 'Dasar-dasar Agribisnis Perikanan', code: 'subject_dasar_dasar_agribisnis_perikanan' },
    { label: 'Dasar-dasar Teknik Perminyakan', code: 'subject_dasar_dasar_teknik_perminyakan' },
    { label: 'Dasar-dasar Pengembangan Perangkat Lunak dan Gim', code: 'subject_dasar_dasar_pengembangan_perangkt_lunak_dan_gim' },
    { label: 'Dasar-dasar Agribisnis Tanaman', code: 'subject_dasar_dasar_agribisnis_tanaman' },
    { label: 'Dasar-dasar Agribisnis Ternak', code: 'subject_dasar_dasar_agribisnis_ternak' },
    { label: 'Dasar-dasar Teknik Energi Terbarukan', code: 'subject_dasar_dasar_teknik_energi_terbarukan' },
    { label: 'Dasar-dasar Desain Pemodelan dan Informasi Bangunan', code: 'subject_dasar_dasar_desain_pemodelan_dan_informasi_bangunan' },
    { label: 'Dasar-dasar Kimia Analisa', code: 'subject_dasar_dasar_kimia_analisa' },
    { label: 'Dasar-dasar Teknik Konstruksi Kapal', code: 'subject_dasar_dasar_teknik_kontruksi_kapal' },
    { label: 'Dasar-dasar Teknik Perawatan Gedung', code: 'subject_dasar_dasar_teknik_perawatan_gedung' },
    { label: 'Dasar-dasar Usaha Pertanian Terpadu', code: 'subject_dasar_dasar_usaha_pertanian_terpadu' },
    { label: 'Dasar-dasar Desain dan Produksi Kriya', code: 'subject_dasar_dasar_desain_dan_produksi_kriya' },
    { label: 'Dasar-dasar Seni Pertunjukan', code: 'subject_dasar_dasar_seni_pertunjukan' },
    { label: 'Dasar-dasar Seni Rupa', code: 'subject_dasar_dasar_seni_rupa' },
    { label: 'Dasar-dasar Teknik Logistik', code: 'subject_dasar_dasar_teknik_logistik' },
    { label: 'Dasar-dasar Teknik Mesin', code: 'subject_dasar_dasar_teknik_mesin' },
    { label: 'Dasar-dasar Teknik Tekstil', code: 'subject_dasar_dasar_teknik_tekstil' },
    { label: 'Dasar-dasar Teknik Geologi Pertambangan', code: 'subject_dasar_dasar_teknik_geologi_pertambangan' },
    { label: 'Dasar-dasar Pemasaran', code: 'subject_dasar_dasar_pemasaran' },
    { label: 'Dasar-dasar Teknik Kimia Industri', code: 'subject_dasar_dasar_teknik_kimia_industri' },
    { label: 'Dasar-dasar Teknik Laboratorium Medik', code: 'subject_dasar_dasar_teknik_labolatorium_medik' },
    { label: 'Dasar-dasar Konstruksi dan Perawatan Bangunan Sipil', code: 'subject_dasar_dasar_kontruksi_dan_perawatan_bangunan_sipil' }
];

export const classLevel = [
    { label: 'I', code: 'class_1' },
    { label: 'VII', code: 'class_7' },
    { label: 'II', code: 'class_2' },
    { label: 'VIII', code: 'class_8' },
    { label: 'III', code: 'class_3' },
    { label: 'IX', code: 'class_9' },
    { label: 'IV', code: 'class_4' },
    { label: 'X', code: 'class_10' },
    { label: 'V', code: 'class_5' },
    { label: 'XI', code: 'class_11' },
    { label: 'VI', code: 'class_6' },
    { label: 'XII', code: 'class_12' }
];