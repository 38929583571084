export const staticBooks = [
    {
        title: "Cerita Eyang",
        description: "",
        type: "pdf",
        id: "1739",
        slug: "cerita-eyang",
        image: process.env.PUBLIC_URL + '/assets/image/cover/cerita-eyang.jpeg',
        attachment: "https://static.buku.kemdikbud.go.id/content/pdf/bukunonteks/pusbuk/Biji_Merah_Luna.pdf",
        class: "",
        level: "A",
        writer: "Ammy Kudo",
        reviewer: "",
        translator: "",
        adapter: "",
        designer: "Maretta Gunawan",
        cover_designer: "",
        ilustrator: "Mel Darmawan",
        editor: "Sofie Dewayani, Dewitrik",
        publisher: "Kementerian Pendidikan, Kebudayaan, Riset, dan Teknologi",
        language: "Indonesia",
        context: "",
        subject: "Antropologi",
        format: "Teks",
        isbn: "978-602-244-926-3",
        curriculum: "2022",
        collation: "",
        edition: "1",
        unit: "Pusat Perbukuan",
        status: "publish",
        category: "buku_non_teks",
        book_type: "buku_siswa",
        version: null,
        created_at: "2023-02-15 21:48:27",
        updated_at: null,
        deleted_at: null
    },
    {
        title: "Waktu Hujan Turun",
        description: "",
        type: "pdf",
        id: "1739",
        slug: "cerita-eyang",
        image: process.env.PUBLIC_URL + '/assets/image/cover/waktu-hujan-turun.jpeg',
        attachment: "https://static.buku.kemdikbud.go.id/content/pdf/bukunonteks/pusbuk/Biji_Merah_Luna.pdf",
        class: "",
        level: "A",
        writer: "Ammy Kudo",
        reviewer: "",
        translator: "",
        adapter: "",
        designer: "Maretta Gunawan",
        cover_designer: "",
        ilustrator: "Mel Darmawan",
        editor: "Sofie Dewayani, Dewitrik",
        publisher: "Kementerian Pendidikan, Kebudayaan, Riset, dan Teknologi",
        language: "Indonesia",
        context: "",
        subject: "Antropologi",
        format: "Teks",
        isbn: "978-602-244-926-3",
        curriculum: "2022",
        collation: "",
        edition: "1",
        unit: "Pusat Perbukuan",
        status: "publish",
        category: "buku_non_teks",
        book_type: "buku_siswa",
        version: null,
        created_at: "2023-02-15 21:48:27",
        updated_at: null,
        deleted_at: null
    },
    {
        title: "Robot Paling Keren",
        description: "",
        type: "pdf",
        id: "1739",
        slug: "cerita-eyang",
        image: process.env.PUBLIC_URL + '/assets/image/cover/robot-paling-keren.jpeg',
        attachment: "https://static.buku.kemdikbud.go.id/content/pdf/bukunonteks/pusbuk/Biji_Merah_Luna.pdf",
        class: "",
        level: "A",
        writer: "Ammy Kudo",
        reviewer: "",
        translator: "",
        adapter: "",
        designer: "Maretta Gunawan",
        cover_designer: "",
        ilustrator: "Mel Darmawan",
        editor: "Sofie Dewayani, Dewitrik",
        publisher: "Kementerian Pendidikan, Kebudayaan, Riset, dan Teknologi",
        language: "Indonesia",
        context: "",
        subject: "Antropologi",
        format: "Teks",
        isbn: "978-602-244-926-3",
        curriculum: "2022",
        collation: "",
        edition: "1",
        unit: "Pusat Perbukuan",
        status: "publish",
        category: "buku_non_teks",
        book_type: "buku_siswa",
        version: null,
        created_at: "2023-02-15 21:48:27",
        updated_at: null,
        deleted_at: null
    },
    {
        title: "Ketika Dama Melaut",
        description: "",
        type: "pdf",
        id: "1739",
        slug: "cerita-eyang",
        image: process.env.PUBLIC_URL + '/assets/image/cover/ketika-dama-melaut.jpeg',
        attachment: "https://static.buku.kemdikbud.go.id/content/pdf/bukunonteks/pusbuk/Biji_Merah_Luna.pdf",
        class: "",
        level: "A",
        writer: "Ammy Kudo",
        reviewer: "",
        translator: "",
        adapter: "",
        designer: "Maretta Gunawan",
        cover_designer: "",
        ilustrator: "Mel Darmawan",
        editor: "Sofie Dewayani, Dewitrik",
        publisher: "Kementerian Pendidikan, Kebudayaan, Riset, dan Teknologi",
        language: "Indonesia",
        context: "",
        subject: "Antropologi",
        format: "Teks",
        isbn: "978-602-244-926-3",
        curriculum: "2022",
        collation: "",
        edition: "1",
        unit: "Pusat Perbukuan",
        status: "publish",
        category: "buku_non_teks",
        book_type: "buku_siswa",
        version: null,
        created_at: "2023-02-15 21:48:27",
        updated_at: null,
        deleted_at: null
    },
    {
        title: "Kue Untuk Bibi Tupai",
        description: "",
        type: "pdf",
        id: "1739",
        slug: "cerita-eyang",
        image: process.env.PUBLIC_URL + '/assets/image/cover/kue-untuk-bibi-tupai.jpeg',
        attachment: "https://static.buku.kemdikbud.go.id/content/pdf/bukunonteks/pusbuk/Biji_Merah_Luna.pdf",
        class: "",
        level: "A",
        writer: "Ammy Kudo",
        reviewer: "",
        translator: "",
        adapter: "",
        designer: "Maretta Gunawan",
        cover_designer: "",
        ilustrator: "Mel Darmawan",
        editor: "Sofie Dewayani, Dewitrik",
        publisher: "Kementerian Pendidikan, Kebudayaan, Riset, dan Teknologi",
        language: "Indonesia",
        context: "",
        subject: "Antropologi",
        format: "Teks",
        isbn: "978-602-244-926-3",
        curriculum: "2022",
        collation: "",
        edition: "1",
        unit: "Pusat Perbukuan",
        status: "publish",
        category: "buku_non_teks",
        book_type: "buku_siswa",
        version: null,
        created_at: "2023-02-15 21:48:27",
        updated_at: null,
        deleted_at: null
    },
    {
        title: "Sayur Super Owie",
        description: "",
        type: "pdf",
        id: "1739",
        slug: "cerita-eyang",
        image: process.env.PUBLIC_URL + '/assets/image/cover/sayur-super-owie.jpeg',
        attachment: "https://static.buku.kemdikbud.go.id/content/pdf/bukunonteks/pusbuk/Biji_Merah_Luna.pdf",
        class: "",
        level: "A",
        writer: "Ammy Kudo",
        reviewer: "",
        translator: "",
        adapter: "",
        designer: "Maretta Gunawan",
        cover_designer: "",
        ilustrator: "Mel Darmawan",
        editor: "Sofie Dewayani, Dewitrik",
        publisher: "Kementerian Pendidikan, Kebudayaan, Riset, dan Teknologi",
        language: "Indonesia",
        context: "",
        subject: "Antropologi",
        format: "Teks",
        isbn: "978-602-244-926-3",
        curriculum: "2022",
        collation: "",
        edition: "1",
        unit: "Pusat Perbukuan",
        status: "publish",
        category: "buku_non_teks",
        book_type: "buku_siswa",
        version: null,
        created_at: "2023-02-15 21:48:27",
        updated_at: null,
        deleted_at: null
    },
    {
        title: "Apa Itu Di Tengah Jalan",
        description: "",
        type: "pdf",
        id: "1739",
        slug: "cerita-eyang",
        image: process.env.PUBLIC_URL + '/assets/image/cover/apa-itu-ditengah-jalan.jpeg',
        attachment: "https://static.buku.kemdikbud.go.id/content/pdf/bukunonteks/pusbuk/Biji_Merah_Luna.pdf",
        class: "",
        level: "A",
        writer: "Ammy Kudo",
        reviewer: "",
        translator: "",
        adapter: "",
        designer: "Maretta Gunawan",
        cover_designer: "",
        ilustrator: "Mel Darmawan",
        editor: "Sofie Dewayani, Dewitrik",
        publisher: "Kementerian Pendidikan, Kebudayaan, Riset, dan Teknologi",
        language: "Indonesia",
        context: "",
        subject: "Antropologi",
        format: "Teks",
        isbn: "978-602-244-926-3",
        curriculum: "2022",
        collation: "",
        edition: "1",
        unit: "Pusat Perbukuan",
        status: "publish",
        category: "buku_non_teks",
        book_type: "buku_siswa",
        version: null,
        created_at: "2023-02-15 21:48:27",
        updated_at: null,
        deleted_at: null
    },
]